import React, { useEffect } from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  // Lazy,
  Pagination,
  Autoplay
} from 'swiper/core';
import "swiper/swiper.min.css";
import "swiper/components/lazy/lazy.min.css"
import "swiper/components/pagination/pagination.min.css"

import careersImg from '../img/careers-bg.jpg'
// import teamSlide1 from '../img/team-slide-1.jpg'
import teamSlide17 from '../img/team-slide-17.jpg'
import teamSlide15 from '../img/team-slide-15.jpg'
import teamSlide18 from '../img/team-slide-18.jpg'
import teamSlide19 from '../img/team-slide-19.jpg'
import teamSlide20 from '../img/team-slide-20.jpg'
import teamSlide21 from '../img/team-slide-21.jpg'
import teamSlide2 from '../img/team-slide-2.jpg'
import teamSlide3 from '../img/team-slide-3.jpg'
import teamSlide4 from '../img/team-slide-4.jpg'
import teamSlide5 from '../img/team-slide-5.jpg'
import teamSlide6 from '../img/team-slide-6.jpg'
import teamSlide7 from '../img/team-slide-7.jpg'
// import teamSlide8 from '../img/team-slide-8.jpg'
import teamSlide9 from '../img/team-slide-9.jpg'
// import teamSlide10 from '../img/team-slide-10.jpg'
import teamSlide11 from '../img/team-slide-11.jpg'
import teamSlide12 from '../img/team-slide-12.jpg'
import teamSlide13 from '../img/team-slide-13.jpg'
import teamSlide14 from '../img/team-slide-14.jpg'
import team from '../img/smashtaps-team.jpg'

const slides = [
  // teamSlide1,
  teamSlide17,
  teamSlide15,
  teamSlide18,
  teamSlide19,
  teamSlide20,
  teamSlide21,
  // teamSlide2,
  // teamSlide3,
  // teamSlide4,
  // teamSlide5,
  // teamSlide13,
  // teamSlide6,
  // teamSlide7,
  // teamSlide12,
  // // teamSlide8,
  // teamSlide9,
  // // teamSlide10,
  // teamSlide11,
  // teamSlide14
]
const COMPANY_ID = 'smashtaps-pvt-ltd-7ke8fzriv'
// class Jobs extends React.Component {
//   // componentDidMount() {
//   //   window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfTCVIS9JzlGxZsIjqMYjVHdw0S0Pv5Z1cCJrJtpQcyzPaNug/viewform?usp=sf_link'
//   // }
//   // render() {
//   //   return null
//   // }
// }
// export default Jobs 

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

export default ({ location }) => {
  return (
    <Layout location={location}>
      <div className="cover-heading-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <h1 className="cover-heading">Let's grow together!</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="video-container">
          <img width="100%" src={team}/>
      {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/IujIbXzF0oQ?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        {/* <iframe width="100%" height="1000" src="https://www.youtube.com/embed/IujIbXzF0oQ?autoplay=1&cc_load_policy=1&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
        {/* <img src={careersImg} width="100%"/> */}
        {/* <div className="cover-wrapper-inner">
          <div className="cover-container">
            <div className="inner cover"></div>
          </div>
        </div> */}
        </div>
      </div>
      <section className="p-t-100 p-b-20 sm-p-t-30 sm-p-b-0">
        <div className="container p-t-100 p-b-100 sm-p-t-30 sm-p-b-30 ">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="">
                Our Mission
              </h1>
              <p className="m-t-50">
              We are on a mission to create world-class software with stunning user experiences, 
              that can make a meaningful impact on our users and clients. We know that it is 
              going to be a marathon, not a sprint. It definitely is going to be an exciting 
              ride! After all, it's the journey that matters, not the destination.
              </p>
              <p>
Your passion for tech and innovation is the most important thing we look for in you. Plus if you 
are energetic, creative &amp; young, we’d definitely love to hear from you!
              </p>
              <p>
              {/* Never doubt that a small group of thoughtful committed people can change the world */}
              </p>
              {/* <p>If you are young, energetic, creative, a person who likes challenges and, most importantly 
               truly passionate about technology and innovation then we would like to hear from you!
               </p> */}
               <p>

               </p>

               <a
                className="btn btn-rounded btn-black btn-lg p-l-40 p-r-40 m-t-15"
                href="#current-openings"
              >
                Join us
              </a>

            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div id="current-openings" className="container p-t-100 p-b-100 sm-p-t-30 sm-p-b-30 ">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="">
                Current Openings
              </h1>
              <br/>
              {/* <p className="m-t-50">
                <a rel="noopener" href="https://www.linkedin.com/jobs/view/2785747690/" className="btn-view-project light">Senior Full-stack JavaScript Developer (ReactJS)</a>
              </p> */}
              <p className="m-t-50">
                <a rel="noopener" href="https://www.linkedin.com/jobs/view/3720330674" className="btn-view-project light">Senior Frontend JavaScript Developer - SSE/ATL (ReactJS)</a>
              </p>
              <p className="m-t-50">
                <a rel="noopener" href="https://smashtaps-tl9koa4y5tx.mollyhq.com/332bbb5c-5968-40c7-b8e4-840606c44792" className="btn-view-project light">Senior Full-Stack Developer - SSE/ATL (ReactJS/Java Spring Boot)</a>
              </p>
             
              <p className="m-t-50">
                <a rel="noopener" href="https://www.linkedin.com/jobs/view/3720334934" className="btn-view-project light">QA Automation Engineer (Cypress)</a>
              </p>
              {/* <p className="m-t-50">
                <a rel="noopener" href="https://www.linkedin.com/jobs/view/2538672538/" className="btn-view-project light">Senior QA Engineer (Manual testing)</a>
              </p> */}
            </div>
          </div>
        </div>
      </section>
      
{/*       
      <section className="p-t-100 p-b-20 sm-p-t-30 sm-p-b-0">
        <div className="container">
        <h1 className="">
                Our Principles
              </h1>
        </div>
        <div className="container-fluid p-t-100 p-b-100 sm-p-t-30 sm-p-b-30 ">
          
          <div className="row">
            <div className="col-sm-4">
              <p className="m-t-50">
             
              </p>

            </div>
          </div>
        </div>
      </section> */}

      <section className="p-t-100 p-b-20 sm-p-t-30 sm-p-b-0 bg-green">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <h1 className="">
                Why you should join us
              </h1>
            </div>
          </div>
        
        </div>
        <div className="container p-t-100 p-b-100 sm-p-t-30 sm-p-b-30 ">
          
          <div className="row">
            <div className="col-sm-4">
              <h3 className="">Startup Culture</h3>
              <p className="">
              {/* We operate like a startup — agile in nature, value creative problem solving, 
              open communication and a flat hierarchy. */}
              We don’t operate as a stuck-up corporate. 
              We have a flat hierarchy &amp; love creative problem-solving. Just how a startup should be.
              </p>

            </div>
            <div className="col-sm-4">
              <h3 className="">Ability to Work from Home</h3>
              <p className="">
                Work from home when you need to because we want everyone to have a healthy work-life balance.
              </p>

            </div>
            <div className="col-sm-4">
              <h3 className="">Flexible Work Hours</h3>
              <p className="">
                The best work doesn't always happen 9-5. We don't care at what time you come to or leave the office,
                as long as you get the work done on time!
              </p>

            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <h3 className="">Awesome People™</h3>
              <p className="">
              {/* We like to hire people who are smart, friendly, talented, hardworking, enthusiastic and fun to work with.
              (they should pass the vibe check!) */}
              We hire people who are fun to work with, smart &amp; hardworking. 
              You’ve gotta pass our vibe check 😉. Enthusiasm, friendliness &amp; talent are great qualities to have. 
              </p>

            </div>
            <div className="col-sm-4">
              <h3 className="">Skill &gt; Paper Qualifications</h3>
              <p className="">
              Qualifications are nice-to-haves at SmashTaps. Prove us that you have the skills we need, and that's all that matters.
              </p>

            </div>
            <div className="col-sm-4">
              <h3 className="">Global Exposure</h3>
              <p className="">
              You will be able to either, work with our foreign clients or work on our 
              own products that target the global market. Global either way.
              </p>

            </div>
          </div>
        </div>
     
          <Swiper slidesPerView={3} spaceBetween={30} 
          // lazy={{
          //   loadPrevNext: true,
          //   loadPrevNextAmount: 3
          // }} 
          autoplay={{
          "delay": 2500,
          "disableOnInteraction": false
        }} loop={true} slidesPerView={'auto'}
        className="mySwiper">
          {slides.map((slide,i) => (
            <SwiperSlide key={i}>
              {/* <img data-src={slide} width="100%" className="swiper-lazy" /> */}
              {/* <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div> */}
              <img alt="Team photo" src={slide} width="100%" />
            </SwiperSlide>
          ))}
          </Swiper>
        <div className="container p-t-100 p-b-100 sm-p-t-30 sm-p-b-30 ">
          <div className="row">
            <div className="col-sm-4">
              <h3 className="">Ability to use the latest tech in the industry</h3>
              <p className="">
              There will never be a dull moment because we always embrace the latest technologies. 
              Always growing. Always learning.
              </p>

            </div>
            <div className="col-sm-4">
              <h3 className="">Access to Most Popular Learning Platforms</h3>
              <p className="">
                {/* We don't expect you to know everything about our tech stack when you join. Sharpen your skills
                 by accessing popular online learning platforms that we provide.  */}
                 When you join, we don't expect you to know everything about our tech stack. 
                 So we give you access to popular learning platforms to sharpen your skills.
              </p>

            </div>
           
            <div className="col-sm-4">
              <h3 className="">Sponsored Professional Certifications</h3>
              <p className="">
                {/* Get sponsored when you take professional certifications that are relevant to our tech stack. */}
                We sponsor you when you take select professional certifications that are relevant to our tech stack.
              </p>

            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <h3 className="">Free Swag, Freebies &amp; Goodies</h3>
              <p className="">
                {/* Free T-shirts, stickers, personalized cake for your birthday and gifts for work anniversary. */}
                Free personalized cake for your birthday, gifts for work anniversary, T-shirts &amp; stickers.
              </p>

            </div>
            <div className="col-sm-4">
              <h3 className="">Work-From-Home Allowance</h3>
              <p className="">
              Internet allowance when you have to Work-From-Home due to the pandemic situation.
              </p>

            </div>
            <div className="col-sm-4">
              <h3 className="">Competitive Salary</h3>
              <p className="">
                {/* Good talent deserves good pay. We offer competitive salaries in the market for the right person. */}
                Great talent deserves great pay. We hire the best. So we pay the best. 
              </p>

            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
            </div>
            <div className="col-sm-4">
              <h3 className="">Medical Insurance</h3>
              <p className="">
              The health and wellbeing of our staff are important to us, so we provide comprehensive medical coverage to members of the staff and their families.
              </p>
            </div>
            <div className="col-sm-4">
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

// export default ({ location }) => {

//   const data = useStaticQuery(graphql`
//   query MyQuery {
//     allMollyJob {
//       nodes {
//         id
//         description
//         experience
//         country
//         title
//         jobType
//         jobRegion
//         jobId
//         isRemoteJob
//         isSalaryNegotiable
//         currency
//         createdAt
//         companyId
//         maxSalary
//         minSalary
//       }
//     }
//   }
  
  
// `)


//     return (
//       <Layout location={location}>
//         <div className="cover-heading-wrapper">
//           <div className="container">
//             <div className="row">
//               <div className="col-sm-10">
//                 <h1 className="cover-heading">Join the dream team!</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="cover-wrapper cover-jobs">
//           <div className="cover-wrapper-inner">
//             <div className="cover-container">
//               <div className="inner cover"></div>
//             </div>
//           </div>
//         </div>
//         <section className="p-t-100 p-b-20 sm-p-t-30 sm-p-b-0">
//           <div className="container p-t-50 p-b-50 sm-p-t-30 sm-p-b-30">
//             <div className="row">
//               <div className="col-sm-8 col-sm-offset-2">
//                 <h1>Current openings</h1>
//                 <div id="molly-container" className="p-t-50 ">


//                 <div className="molly__jobs">
//                   {data.allMollyJob.nodes.map(job => (
//                     <div className="molly__job" key={job.jobId}>
//                       <a className="molly__job__link" target="_blank"
//                         rel="noreferrer"
//                         href={`https://app.hellomolly.io/jobs/${COMPANY_ID}/${job.jobId}`}>
//                         {job.title}
//                       </a>
//                       <p className="molly__job__info">
//                         <span className="molly__job__info-type">{job.jobType}</span>
//                         <span className="molly__job__info-middot">&middot;</span>
//                         <span className="molly__job__info-location">{job.country}</span>
//                       </p>
//                     </div>
//                   ))}
//                 </div>
                
//                 </div>

//               </div>
//             </div>
//           </div>
//         </section>
//       </Layout>
//     )
// }
